//
// Detail gallery slider
//

.detail-gallery-slider {
  height: 75vh;
  &__item {
    display: block;
    text-align: center;
    img {
      max-height: 75vh !important;
    }
    .thumbnail {
      display: none;
    }
  }
}

.detail-gallery-slider-pagination.swiper-pagination {
  position: static;
  padding: .5rem;
  flex-wrap: wrap;
  > .swiper-pagination-bullet {
    padding: 0;
    margin: 0 .1rem;
    width: 50px;
    height: 50px;
    opacity: 0.75;
    transition: all 0.1s cubic-bezier(0.15, 0.5, 1, 0.5);
    border-radius: 0;
    border: solid 2px $border-color;
    &-active {
      border-color: $primary;
      opacity: 1;
    }
  }
  .swiper-lazy-preloader {
    margin-top: 30vh;
  }
}
