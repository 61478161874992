//
// Detail banner slider
//

.detail-banner-slider {
  &__item {
    display: block;
    text-align: center;
  }
  .swiper-pagination-bullet {
    background: $white;
    opacity: .75;
    &-active {
      background: $primary;
      opacity: 1;
    }
  }
}