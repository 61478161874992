//
// Navbar
//

.navbar-nav {
  margin-left: auto;
  .nav-item {
    text-transform: uppercase;
    @include font-size($font-size-sm);
  }
  .nav-link {
    padding-top: $navbar-nav-link-padding-y;
    padding-bottom: $navbar-nav-link-padding-y;
    @include media-breakpoint-down(md) {
      padding-top: $navbar-nav-link-padding-y / 2;
      padding-bottom: $navbar-nav-link-padding-y / 2;
    }
    &:hover {
      color: $light;
      background: adjust-color($color: $primary, $lightness: -2.5%);
    }
  }
}

.navbar {
  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }
  .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }
}
