.listing-small-wrapper {
	margin: 0 0 -15px 0;
}

.listing-small {
	@extend .clearfix;

	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin: 0 0 15px 0;	
	transition: box-shadow .15s linear;

	&:hover {
		box-shadow: 0 2px 3px rgba(#000, .12);

		.listing-small-image {
			a {
				&:after, &:before {
					opacity: 1;
				}
			}
		}

		.listing-small-content h3 {
			color: $primary;

			a {
				color: $primary;
			}
		}
	}
}

.listing-small-image {
	float: left;
	height: 70px;	
	overflow: hidden;
	position: relative;
	width: 30%; 

	a {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 3px 0 0 3px;
		bottom: 0;
		display: block;		
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;

		&:after {
			background-color: rgba(#000, .3);
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: opacity .15s linear;
		}

		&:before {
			-webkit-font-smoothing: antialiased;
			background-color: $primary;
			border-radius: 50%;
			color: #fff;
			content: '\f002';
			display: block;
			font-family: 'FontAwesome';
			height: 30px;
			left: 50%;
			line-height: 28px;
			opacity: 0;
			position: absolute;
			text-align: center;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			transition: opacity .15s linear;
			width: 30px;
			z-index: 10;
		}
	}
}

.listing-small-content {
	float: left;
	display: block;;
	padding: 15px;
	width: 70%;

	h3 {		
		@extend .text-truncate;

		display: block;
		font-size: 1em;
		font-weight: 600;
		line-height: 1.2;
		margin: 0;
		padding: 0;		

		a {			
			color: $color-black;
			transition: color .15s linear;

			&:hover {
				color: $primary; 
				text-decoration: none;
			}
		}
	}	

	h4 {
		color: $color-gray-darker;
		font-size: .9em;
		margin: 8px 0 0 0;

		a {
			border-bottom: 1px solid rgba($primary, .3);
			color: $primary;
			padding: 0 0 2px 0;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}
	}
}