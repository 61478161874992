.faq-item {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin: 0 0 30px 60px;
	padding: 20px 30px;
	position: relative;

	&:before {
		background-color: $secondary;
		border-radius: 3px;
		color: #fff;
		content: 'Q';
		display: block; 
		font-size: 1.25em;
		font-weight: 600;
		height: 40px; 
		left: -60px;
		line-height: 40px;
		position: absolute;
		text-align: center;
		top: 0;
		width: 40px;
	}
}

.faq-item-question {
	h2 {
		font-size: 1.4em;
		font-weight: 700;		
		margin: 0 0 20px 0;
		padding: 0;
	}
}

.faq-item-answer {
	color: lighten($color-gray-darker, 10%);
}

.faq-item-meta {
	border-top: 1px solid rgba(#000, .04);
	padding: 20px 0 0 0;
	text-align: right;

	.rate {
		margin: 0 0 0 20px;

		a {
			font-weight: 700;
		}

		.separator {
			color: $color-gray-dark;
			margin: 0 10px;
		}
	}
}