.filter {
	background-color: #fff;	
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .1);	
	padding: 25px;

	h2 {
		border-bottom: 1px solid $color-gray;
		font-size: 1em;
		margin: 0 0 20px 0;
		padding: 10px 0 10px 0;
	}

	&.filter-gray {
		h2 {
			border-color: $color-gray-medium;
		}
	}

	&.filter-primary, &.filter-secondary {
		h2 {
			border-color: rgba(#fff, .3);
			color: #fff; 
		}
	}

	&.filter-gray {
		background-color: $color-gray;
	}

	&.filter-primary {
		background-color: $primary;

		label {
			color: rgba(#fff, .9);
		}

		.form-control {
			border: 0;
			border-radius: 0;
		}

		.btn {
			@extend .btn-secondary;
		}
	}

	&.filter-secondary {
		background-color: $secondary;

		label {
			color: rgba(#fff, .9);
		}

		.form-control {
			border: 0;
			border-radius: 0;
		}
	}	
}

.filter-options {
	background-color: rgba(#000, .015);
	border-radius: 0 0 3px 3px;
	border-top: 1px solid rgba(#000, .05);	
	margin: 15px -25px -25px -25px;
	padding: 25px 15px 0 15px;

	strong {
		color: $color-gray-darker;
		display: inline-block;
		font-size: .9em;
		margin: 2px 30px 0 0;
		text-transform: uppercase;
		vertical-align: top;
	}

	.filter-primary &, .filter-secondary & {
		background-color: rgba(#fff, .05);
		border-top-color: rgba(#fff, .1); 

		strong {
			color: rgba(#fff, .9);
		}
	}
}

.filter-sort {
	float: left;
}

.filter-order {			
	float: right;
}