
blockquote {
	background-color: #fff;
	font-style: italic;
	font-size: 1.5em;	
	padding: 20px 30px;

	p {
		color: $color-black !important;
		line-height: 1.7;		
	}

	cite {
		color: $color-gray-darker;
		float: right;
		font-size: .7em;
		font-weight: 400;
		margin: 30px 0 0 0;
	}
}

hr {
	border-color: lighten($color-gray-medium, 5%);
	margin: 60px 0;
	padding: 0;
}