.call-to-action {
	@extend .clearfix;

	background-color: #fff;
	margin: 0 -1000px;
	padding: 60px 1000px;
	position: relative;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}		

	h1 {
		font-size: 2.2em;
		font-weight: 400;
		line-height: 1.5;
		margin: 0;
		padding: 0;
		white-space: nowrap;

		@include media-breakpoint-down(sm) {
			white-space: inherit;
		}
	}

	h2 {
		color: lighten($color-gray-darker, 10%);
		font-size: 1.2em;
		font-weight: 400;
		line-height: 1.5;
		margin: 5px 0 0 0;
		padding: 0;	
		white-space: nowrap;	

		@include media-breakpoint-down(sm) {
			white-space: inherit;
		}		
	}

	.btn {
		float: right;
		font-size: 1.2em;
		font-weight: 400;
		padding: 12px 25px;		
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include media-breakpoint-down(sm) {
			display: inline-block;
			float: none;
			margin: 30px 0 0 0;
			position: relative;
			top: auto;
			transform: none;
		}
	}

	.logo-shape {
		float: left;
		margin: 25px 30px 0 0;

		@include media-breakpoint-down(sm) {
				float: none;
				margin: 0 0 50px 0;
			}		
		}
}

.call-to-action-inner {
	@extend .clearfix;

	position: relative;
}

.call-to-action-title {
	float: left;

	@include media-breakpoint-down(sm) {
		float: none;
	}	
}

.listing-detail .call-to-action-small, .call-to-action-small {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin: 0 0 30px 0;
	text-align: center;
	padding: 30px 20px 20px 20px;

	h1 {
		border: 0;
		font-size: 1.6em;
		margin: 0;
		padding: 0;
	}

	h2 {
		border: 0;
		color: lighten($color-gray-darker, 10%);
		font-size: 1.2em;
		margin: 15px 0 40px 0;
		padding: 0;
	}

	.btn {
		margin: 0 10px;
	}
}

.call-to-action-small-buttons {
	background-color: rgba(#000, .02);
	border-radius: 0 0 3px 3px;
	margin: 0 -20px -20px -20px;
	padding: 20px 20px 20px 20px;
}
