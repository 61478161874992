.alert {
	border: 0;
	border-radius: 0;
	padding: 20px 30px;
}

.alert-success {
	background-color: $color-green;
	color: #fff;

	.alert-link {
		color: #fff;
	}
}

.alert-danger {
	background-color: $color-red;
	color: #fff;

	.alert-link {
		color: #fff;
	}
}

.alert-info {
	background-color: $color-blue;
	color: #fff;

	.alert-link {
		color: #fff;
	}
}

.alert-warning {
	background-color: $color-yellow;
}