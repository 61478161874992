.customizer {
	left: -190px; 
	position: fixed;
	top: 200px;
	transition: all .15s linear;
	z-index: 99999;

	&.open {
		left: 0;
	}
}

.customizer-title {
	display: inline-block;
	cursor: pointer;
	position: absolute;
	right: -76px;
	top: 43px;

	span {
		background-color: $secondary;;
		color: #fff;
		display: block;
		padding: 6px 10px; 
		text-align: center;
		transform: rotate(-90deg);
		width: 120px;

		.fa {
			margin: 0 5px 0 0;
		}
	}
}

.customizer-content {
	@extend .clearfix;

	background-color: #fff;
	box-shadow: 0 1px 2px rgba(#000, .08);
	padding: 20px;
	width: 190px;

	h2 {
		border-bottom: 1px solid rgba(#000, .04);
		font-size: 1.05em;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			float: left;
			margin: 0 7px 5px 0;

			&:nth-child(5n) {
				margin-right: 0;
			}
		}

		a {			
			background-color: $primary;
			display: block;
			height: 24px;			
			width: 24px;
		}
	}
}

.background-turquoise {
	background-color: $color-turquoise !important;
}

.background-orange {
	background-color: $color-orange !important;
}

.background-orange-dark {
	background-color: $color-orange-dark !important;
}

.background-purple {
	background-color: $color-purple !important;
}

.background-cyan {
	background-color: $color-cyan !important;
}

.background-teal {
	background-color: $color-teal !important;
}

.background-magenta {
	background-color: $color-magenta !important;
}

.background-green {
	background-color: $color-green !important;
}

.background-green-dark {
	background-color: $color-green-dark !important;
}

.background-red {
	background-color: $color-red !important;
}

.background-brown {
	background-color: $color-brown !important;
}

.background-blue {
	background-color: $color-blue !important;
}

.background-blue-gray {
	background-color: $color-blue-gray !important;
}

.background-yellow {
	background-color: $color-yellow !important;
}