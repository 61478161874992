.box {
  padding-bottom: 60px;
  padding-top: 50px;
	text-align: center;
	position: relative;

  &:hover {
    .box-icon {
      color: $primary;

      .boxes-circle & {
        background-color: $primary;
        color: #fff;
      }

      .boxes-colored & {
        color: #fff;
      }
    }

    .box-content {
      h2 {
        color: $primary;

        .boxes-colored & {
          color: #fff;
        }
      }
    }
  }
}

.boxes-colored {
  background-color: $primary;

  .row {
    div {
      margin-left: 0;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;

      &:first-child {
        .box {
          background-color: darken($primary, 2%);
          margin-left: -1000px;
          padding-left: 1000px;
        }
      }

      &:last-child {
        .box {
          background-color: lighten($primary, 2%);
          margin-right: -1000px;
          padding-right: 1000px;
        }
      }
    }
  }
}

.box-icon {
  color: darken($color-gray-dark, 10%);
  font-size: 3.4em;
  transition: background-color 0.15s linear, color 0.15s linear;

  .boxes-circle & {
    background-color: $secondary;
    border-radius: 50%;
    color: #fff;
    font-size: 3em;
    height: 80px;
    line-height: 76px;
    margin: 0 auto 20px auto;
    text-align: center;
    width: 80px;
  }

  .boxes-aligned & {
    font-size: 2.9rem;
    float: left;
    margin-right: 30px;
  }

  .boxes-colored & {
    color: #fff;
  }
}

.box-content {
	a {
		text-decoration: none;
	}

  h2 {
    color: $color-black;
    font-size: 1.6em;
    font-weight: 400;
    margin: 15px 0 0 0;
    padding: 0;
    transition: color 0.15s linear;

    .boxes-aligned & {
      margin-top: 25px;
    }

    .boxes-colored & {
      color: #fff;
    }
  }

  p {
    color: $color-gray-darker;
    font-size: 1em;
    margin: 30px 0 0 0;

    .boxes-colored & {
      color: rgba(#fff, 0.85);
    }
  }

  .boxes-aligned & {
    display: table-cell;
    text-align: left;
  }
}

.box-small {
  background-color: #fff;
  padding: 30px;
}
