// 
// Detailed listing slider
// 

.listing-box-detailed-slider .swiper-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include media-breakpoint-down(md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}