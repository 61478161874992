//
// About page styles
//

.about-intro {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.about-featured {
  background-color: #1b273d;
  .box {
    padding: 20px !important;
  }
  .box:hover i {
    color: white !important;
  }
}

.about-qualities {
  .fa {
    color: $primary;
  }
}
