// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              true !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-grid-classes:         true !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

$color-black: #323232;
$color-black-light: #626262;

$color-gray-darker: #666666;
$color-gray-dark: #dedede;
$color-gray-medium: #dddddd;
$color-gray: #f3f3f3;
$color-gray-light: #fafafa;

$color-turquoise: #0bb7a5;
$color-orange: #ec8c6f;
$color-orange-dark: #bb6733;
$color-purple: #8e5c90;
$color-cyan: #26c6da;
$color-teal: #009688;
$color-magenta: #bf224e;
$color-green: #25b45b;
$color-green-dark: #429a66;
$color-red: #f75156;
$color-brown: #a1887f;
$color-blue: #06a7ea;
$color-blue-gray: #78909c;
$color-yellow: #ffdc27;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$primary: #006032 !default;
$secondary: #363433 !default;

$body-color: $secondary !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1100px,
  xxl: 1200px
) !default;

$fa-font-path: '' !default;
$font-family-raleway: 'Raleway', 'Arial', sans-serif !default;
$font-family-opensans: 'Open Sans', 'Arial', sans-serif !default;
$font-family-base: $font-family-opensans !default;
$headings-font-family: $font-family-raleway !default;

$font-size-base:              1rem !default;

$rfs-base-font-size: 1rem !default;
$rfs-font-size-unit: rem !default;
$rfs-factor: 100 !default;

$headings-margin-bottom: $spacer !default;

$h1-font-size:                $font-size-base * 2.25 !default;
$h2-font-size:                $font-size-base * 1.75 !default;
$h3-font-size:                $font-size-base * 1.3 !default;
$h4-font-size:                $font-size-base * 1.2 !default;
$h5-font-size:                $font-size-base * 1.1 !default;
$h6-font-size:                $font-size-base !default;

// Navbar

$navbar-padding-y:                  0 !default;
$navbar-padding-x:                  null !default;

$navbar-brand-height:               6rem !default;
$navbar-brand-padding-y:            .5rem !default;

$navbar-nav-link-padding-x:         1rem !default;
$navbar-nav-link-padding-y:         1.25rem !default;

// Aspect Ratios

$embed-responsive-aspect-ratios: (
  "17by5": (
    x: 17,
    y: 5
  ),
  "21by9": (
    x: 21,
    y: 9
  ),
  "16by9": (
    x: 16,
    y: 9
  ),
  "4by3": (
    x: 4,
    y: 3
  ),
  "1by1": (
    x: 1,
    y: 1
  )
);
