.pricing {	
	.pricing-boxed & {
		margin: 0 15px;
	}
}

.pricing-col-wrapper {
	.pricing-boxed & {
		margin: 0;
		padding: 0;
	}
}
 
.pricing-col {
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(#000, .08);
	border-radius: 3px;
	padding: 20px;

	.pricing-boxed & {
		border-radius: 0;
	}

	.pricing-bold & {
		padding: 30px;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
	}
}

.pricing-title {
	border-bottom: 1px solid rgba(#000, .04);
	font-size: 2em;
	margin: 0 0 20px 0;
	padding: 10px 0 20px 0;
	text-align: center;

	.pricing-bold & {
		font-size: 1.5em;
		font-weight: 700;
		padding: 0 0 20px 0;
		text-align: left;
	}
}

.pricing-price {
	color: $primary;
	font-size: 1.8em;
	font-weight: 600;
	margin: 30px 0 20px 0;
	text-align: center;

	.pricing-bold & {
		font-size: 2.8em;
		font-weight: 700;
		margin: 0 0 20px 0;
		text-align: left;
	}
}

.pricing-currency {
	font-size: .7em;
	font-weight: 600;
	vertical-align: top; 

	.pricing-bold & {
		font-size: .5em;
		vertical-align: 15px;
	}
}

.pricing-description {
	font-weight: 700;
	text-align: center;

	.pricing-bold & {
		border-bottom: 1px solid rgba(#000, .03);
		padding: 0 0 10px 0;
		text-align: left;
	}
}

.pricing-features {
	list-style: none;
	margin: 30px 0 40px 0;
	padding: 0;
	text-align: center;

	li {
		padding: 6px 0;

		.pricing-boxed & {
			margin: 0;
			padding: 12px 0;

			&:nth-child(odd) {
				background-color: rgba(#000, .03);
			}
		}
	}

	.pricing-bold & {
		text-align: right;
	}
}

.pricing-btn {
	@extend .btn, .btn-primary, .btn-block; 

	.pricing-col:hover & {
		@extend .btn-secondary;
	}
}