.nav-tabs {
	background-color: rgba(#000, .04);
	border: 0;
	border-radius: 3px;
	display: table;
	margin: 0 0 30px 0;
	table-layout: fixed;
	width: 100%;

	.nav-item {
		display: table-cell;		
		float: none;
		text-align: center;

		&:first-child {
			border-radius: 3px 0 0 3px;
		}

		&:last-child {
			border-radius: 0 3px 3px 0;
		}

		@include media-breakpoint-down(md) {
			display: block;
			width: 100% !important;
		}
	}

	.nav-link {
		border: 0;
		border-radius: 0;
		color: $color-black;
		display: block;
		font-weight: 600;
		line-height: 51px;
		padding: 0;
		transition: all .15s linear;		
		white-space: nowrap;

		&.active { 
			background-color: rgba(#000, .05) !important;
		}		

		&:hover {
			color: $primary;
		}
	}
}

@for $i from 1 through 6 {
	.nav-tabs-items-#{$i} {
		.nav-item {
			width: 100% / $i;
		}
	}
}