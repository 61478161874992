// 
// Master page styles
// 

.page-footer {
  background-color: $secondary;
  color: $light;
  @include font-size($font-size-sm);
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  img {
    margin-bottom: $paragraph-margin-bottom;
  }
  a {
    color: $light;
  }
  &__widgets {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    display: grid;
    grid-gap: 2rem 6rem;
    grid-template-columns: 1fr 1fr 1fr;
    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      h3 {
        @include font-size($font-size-base);
        font-weight: bolder;
      }
    }
  }
  &__bottom {
    background-color: darken($secondary, 5%);
    padding-top: 1rem;
    padding-bottom: 1rem;
    .container {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }
    svg {
      fill: $gray-300;
    }
  }
}

.footer-social {
  list-style: none;
  padding-left: 0;
  &__item {
    display: inline;
  }
  a {
    @include font-size($font-size-lg);
  }
}

.footer-list {
  list-style: none;
  padding-left: 0;
  &__item {
    margin-bottom: .25rem;
  }
}