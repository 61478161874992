.listing-detail, .post-detail {
	margin: 0 0 60px 0;
	
	h2 {
		border-bottom: 1px solid rgba(#000, .04);
		font-size: 1.5em;
		margin: 0 0 30px 0;
		padding: 20px 0 20px 0;
	}

	h3 {
		font-size: 1.1rem;
		margin: 0 0 30px 0;
		padding: 20px 0 0 0;
	}

	p {
		color: lighten($color-gray-darker, 5%);
		margin: 0 0 30px 0;
	}
}

.listing-detail-attributes {
	display: flex;
	justify-content: space-around;
	@include media-breakpoint-down(md) {
		flex-direction: column;
	}
}

.listing-detail-attribute {	

	@include media-breakpoint-down(md) {
		margin-bottom: .75rem;
	}

	.key, .value {
		display: block;
	}

	.key {
		color: darken($color-gray-dark, 10%);
		@include font-size($font-size-sm);
		font-weight: $font-weight-bolder;
		text-transform: uppercase;
	}

	.value {
		color: darken($color-gray-darker, 10%);
		@include font-size($h4-font-size);
		font-weight: $font-weight-bolder;

		@include media-breakpoint-down(md) {
			font-size: 1.2em;
		}
	}

	.value-image {
		background-color: $color-gray-medium;
		border-radius: 50%;
		display: inline-block;
		height: 30px;
		margin: 0 10px 0 0;
		position: relative;
		transition: background-color .15s linear;
		vertical-align: -8px;
		width: 30px;

		a {		
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 50%;
			bottom: 2px;
			left: 2px;
			position: absolute;
			right: 2px;
			top: 2px;
		}

		&:hover {
			background-color: $primary;
		}
	}
}

.gallery {
	margin: 0 0 30px 0;
	position: relative;

	.owl-nav {
		bottom: 0;
		left: 0;
		position: absolute;
	}

	.owl-prev {
		border-right: 1px solid rgba(#000, .1);
	}

	.owl-prev, .owl-next {
		background-color: rgba($primary, .75);
		color: #fff;
		float: left;
		height: 60px;
		position: relative;
		transition: background-color .15s linear;
		width: 60px;

		&:hover {
			background-color: rgba($primary, .9);
		}

		.fa {
			display: block;
			left: 50%; 
			position: absolute;
			transform: translateX(-50%) translateY(-50%);
			top: 50%;
		}
	}
}

.gallery-item {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 408px;
}

.overview {
	margin: 0 0 50px 0;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			@extend .clearfix;
			border-bottom: 1px solid rgba(#000, .02);
			padding: 8px 0;

			strong {
				color: lighten($color-gray-darker, 10%);
				font-weight: 400;
			}

			span {
				float: right;
				font-weight: 600;
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

.valuation {	
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.valuation-item {
	margin-bottom: 1rem;
}

.valuation-item-key {
  @include media-breakpoint-up(md) {
		flex: 0 0 25%;
	}
	font-weight: $font-weight-bolder;
}
 
.valuation-item-value {

  @include media-breakpoint-up(md) {
		padding-left: .5rem;
		flex: 0 0 75%;
	}

	span {
		background-color: $primary;
		display: block;
		height: $font-size-base * $line-height-base;
		position: relative;
		max-width: 90%;

		&:after {
			background-color: rgba(#000, .2);
			bottom: 0;
			content: '';
			height: 3px;
			left: 0;
			position: absolute;
			right: 0;
		}

		strong {
			background-color: $secondary;
			bottom: 0;
			color: #fff;
			display: block;
			font-size: .9em;
			font-weight: 400;	
			padding: 2px 10px;		
			position: absolute;
			left: 100%;
			top: 0;
		}
	}
}

.amenities {
	@extend .clearfix;
	list-style: none;
	margin: 0 0 30px 0;
	padding: 0;

	li {
		@extend .col-md-6, .col-md-4, .col-lg-3;
		padding: 8px 0;

		&:before {
			display: inline-block; 
			width: 24px;
		}

		&.yes {
			&:before {
				color: $primary;
				content: '\f00c';
				font-family: 'FontAwesome';
			}
		}

		&.no {
			&:before {	
				color: $color-gray-dark;			
				content: '\f00d';
				font-family: 'FontAwesome';
			}
		}		
	}	
}