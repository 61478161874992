//
// Homepage styles
//

.page-default {
  .page-header {
    background: $primary;
    background: linear-gradient(
      0deg,
      adjust-color($color: $primary, $lightness: 5%) 0%,
      adjust-color($color: $primary, $lightness: -5%) 100%
    );
    a {
      color: $light;
      text-decoration: none;
    }
  }
  .actionbar {
    border-bottom: solid $border-width adjust-color($color: $light, $alpha: -0.5);
  }
  .navbar-nav {
    .nav-link {
      color: $light;
    }
  }
  .navbar {
    .navbar-toggler {
      color: $navbar-dark-color;
      border-color: $navbar-dark-toggler-border-color;
    }
    .navbar-toggler-icon {
      background-image: escape-svg($navbar-dark-toggler-icon-bg);
    }
  }
}

.default-launches {
  background-color: $gray-200;
  padding-top: 3rem;
  padding-bottom: 3rem;
  &__title {
    color: $primary;
    text-align: center;
  }
}

.default-ongoing {
  background-color: $white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  &__title {
    color: $primary;
    text-align: center;
  }
}

.default-done {
  background-color: $gray-200;
  padding-top: 3rem;
  padding-bottom: 3rem;
  &__title {
    color: $primary;
    text-align: center;
  }
}
