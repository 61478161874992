.col-md-20,
.col-md-20,
.col-md-20,
.col-lg-20,
.col-xl-20 {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 20%;
}

.center {
  text-align: center;
}

.box-small {
  background-color: #fff;
  margin: 0 0 30px 0;
}

.aligncenter {
  display: block !important;
  margin: auto;
  margin-bottom: 22px;
  text-align: center;
}

.alignleft {
  float: left;
  margin: 0 30px 22px 0;
}

.alignright {
  float: right;
  margin: 0 0 22px 30px;
}

.fullwidth {
  margin-left: -1000px;
  margin-right: -1000px;
  padding-left: 1000px;
  padding-right: 1000px;
}

.pull-top {
  margin-top: -60px;
}

.pull-bottom {
  margin-bottom: -60px;
}

.push-top {
  margin-top: 60px;
}

.push-bottom {
  margin-bottom: 60px;
}

.logo-shape {
  &:after,
  &:before {
    background-color: rgba($primary, 0.8);
    content: '';
    display: inline-block;
    height: 36px;
    margin-top: -8px;
    transform: rotate(-45deg);
    vertical-align: middle;
    width: 36px;

    .header-small & {
      height: 24px;
      width: 24px;
    }
  }

  &:after {
    margin-left: -10px;
  }
}

.background-white {
  background-color: #fff;
}

.information-bar {
  background-color: $primary;
  background-image: linear-gradient(to right, darken($primary, 5%), lighten($primary, 5%));
  color: #fff;
  font-size: 1.05em;
  font-weight: 400;
  line-height: 2;
  padding: 20px 0;
  text-align: center;

  .fa {
    font-size: 1.25em;
    margin: 0 1rem 0 0;
    vertical-align: middle;
  }

  a {
    border-bottom: 1px solid rgba(#fff, 0.7);
    color: #fff;
    padding-bottom: 2px;

    &:hover {
      text-decoration: none;
    }
  }
}

.page-subheader {
  padding-top: 60px;

  h2 {
    color: darken($color-gray-darker, 10%);
    font-size: 2em;
    font-weight: 300;
    margin: 0 0 30px 0;
  }

  h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    font-size: 1.5em;
    margin: 0 0 30px 0;
    padding: 0 0 20px 0;
  }

  p {
    color: lighten($color-gray-darker, 15%);
    font-size: 1.15em;
    line-height: 2;
    margin: 0;
  }

  .btn {
    margin: 30px 0 0 0;
  }
}

.page-subheader-small {
  padding: 0;
}

.warning {
  text-align: center;

  h1 {
    color: $primary;
    font-size: 18em;
    text-shadow: 8px 8px rgba(#000, 0.15);

    @include media-breakpoint-down(sm) {
      font-size: 10em;
    }
  }

  p {
    font-size: 2em;
    margin: 30px 0 0 0;
  }
}

.contact {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(#000, 0.08);
  font-size: 0.95em;
  width: 100%;

  td,
  th {
    padding: 12px;
    vertical-align: top;
  }
}

.contact-map {
  height: 300px;
  margin: -60px 0 60px 0;
}

.image-wrapper {
  background-color: #fff;
  position: relative;
}

.image-holder {
  // background-image: url('/assets/img/tmp/tmp-9.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image-description-wrapper {
  padding-bottom: 120px;
  padding-top: 120px;

  h2 {
    margin: 0 0 30px 0;
    font-size: 2em;
  }

  p {
    color: lighten($color-gray-darker, 10%);
    margin: 0 0 30px 0;
  }
}

.fullwidth-wrapper {
  display: table;
  height: 100%;
  margin: -60px 0;
  padding: 0;
  width: 100%;

  > .row {
    display: table-row;

    > div {
      display: table-cell;
      float: none;
      overflow: hidden;
      padding: 0;
      vertical-align: top;

      &:first-child {
        padding: 30px;
      }
    }
  }
}
