// 
// Details page layout
// 

.details-banner {
  background-color: $gray-200;
}

.details-listing-info {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.details-description {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $gray-200;
}

.details-tour {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.details-plans {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.details-location {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $gray-200;
}

.details-construction {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.details-progress {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $gray-200;
}

.details-contact {
  padding-top: 2rem;
  padding-bottom: 2rem;
}