//
// Administration page styles
//

.header-transparent .header-top {
  background-color: white;
}

.verdeOliva {
  color: #056c3d;
}

.FundoVerde,
.FundoAmarelo {
  color: white !important;
}

.FundoAmarelo {
  background-color: #056c3d;
  padding: 30px 25px 25px 25px;
}

.FundoVerde {
  background-color: #f7b200;
  padding: 30px 0px 21px 0px;
}

.FundoVerde h4 {
  border-bottom-style: solid;
  border-color: white;
  border-width: 3px;
  padding-bottom: 5px;
  width: 50%;
  left: 25%;
  position: relative;
}

.subtitulo {
  letter-spacing: 2px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom-style: solid;
  border-width: 3px;
  border-color: #056c3d;
}

h5 {
  margin-bottom: 15px;
  color: #056c3d;
}

p {
  line-height: 25px;
}

.LinhaDestaque {
  background-color: #1b273d;
  padding-left: 10%;
  padding-right: 10%;
}

.LinhaDestaque .box:hover i {
  color: white !important;
}

.LinhaDestaque i {
  font-size: 1.2em;
  margin-top: 15px;
}

.LinhaDestaque h2 {
  line-height: 22px;
  margin-top: 10px;
}

.LinhaDestaque .box {
  padding: 20px !important;
}

.LinkOrcamento {
  padding-top: 30px;
}

.FundoVerde a {
  cursor: pointer;
  color: white !important;
}

.FundoVerde a:hover {
  color: white !important;
  text-decoration: none;
}
