.main {
}

.page-wrapper {
	display: table;
	height: 100%;
	overflow: hidden; 
	table-layout: fixed;	
	width: 100%;
}

.header-wrapper {
	display: table-row;
	height: 1px;
}

.main-wrapper {
	display: table-row;	
}

.footer-wrapper {
	display: table-row;
	height: 1px;
}