.landing-image {
	// background-image: url('/assets/img/tmp/landing.jpg');
	background-position: 0 -600px;
	background-repeat: no-repeat;
	//background-size: cover;
	height: 480px;
	position: relative;
	text-align: center;

	&:after {
		background-color: rgba(#000, .55);
		bottom: 0;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	h1 {
		color: #fff;
		font-size: 3.7em;
		font-weight: 700;
		margin: 30px 0 0 0;
	}

	h2 {
		color: #fff;
		font-size: 1.4em;
		font-weight: 400;
		margin: 20px 0 0 0;
	}
}

.landing-image-inner {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 10;
}

.landing-demos {	
	margin: 60px 0 60px 0;

	h1 {		
		color: $color-black;
		font-size: 3.7em;
		font-weight: 600;
		margin: 0 0 40px 0;
		text-align: center;
	}

	p {
		color: lighten($color-gray-darker, 15%);
		font-size: 1.15em;
		line-height: 1.8; 
		margin: 0 auto 60px auto;
		text-align: center;
		width: 80%;
	}
}

.landing-demo {
	@extend .clearfix;

	box-shadow: 0 1px 5px rgba(#000, .08);
	display: block;
	margin: 0 0 30px 0;
	overflow: hidden;
	transition: all .15s linear; 

	&:hover {				
		box-shadow: 0 1px 5px rgba(#000, .12);
		text-decoration: none;

		.landing-demo-image {
			&:after, &:before {
				opacity: 1;
			}
		}
	}
}

.landing-demo-image {
	background-color: #fff;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100% auto;		
	border-radius: 3px 3px 0 0;
	display: block;
	height: 290px;
	margin: 0 -2px;
	position: relative;
	transition: all .3s linear;

	&:before {
		background-color: rgba(#000, .25);
		bottom: 0;
		content: '';
		display: block;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity .15s linear;
		z-index: 9;
	}

	&:after {
		-webkit-font-smoothing: antialiased;
		background-color: $primary;
		border-radius: 50%;
		content: '\f002';
		color: #fff;
		display: block;
		font-family: 'FontAwesome';
		height: 36px;
		left: 50%;
		line-height: 34px;
		opacity: 0;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		transition: opacity .15s linear;
		width: 36px;
		z-index: 10;
	}
}

.landing-demo-title {
	background-color: #fff;
	border-radius: 0 0 3px 3px;
	border-top: 1px solid rgba(#000, .05);
	color: $color-gray-darker; 
	display: block;
	font-size: 1.1em;
	font-weight: 400;
	margin: 0;
	padding: 18px 0;
	text-align: center;
}