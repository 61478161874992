.partners {	
	@extend .clearfix;

	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin: 60px 0 0 0;
	padding: 30px 0;
	position: relative;

	img {
		max-height: 36px;
	}

	&:hover {
		img {
			opacity: .3;
		}
	}
}

.partner-wrapper {
	float: left;
	padding: 15px 30px;
	position: relative;
	text-align: center;
	width: 20%;

	@include media-breakpoint-down(md) {
		width: 50%;
	}

	&:after {
		background-color: rgba(#fff, .4);
		bottom: 20%;
		content: '';
		position: absolute;
		right: 0;
		top: 20%;
		width: 1px; 
	}

	img {
		opacity: .9;
		transition: opacity .15s linear;
	}

	&:hover {
		img {
			opacity: 1;
		}
	}
}

.partner-wrapper-top-border {
	&:before {
		background-color: rgba(#000, .08);
		content: '';
		display: block;
		height: 1px;
		left: 10%;
		position: absolute;
		right: 10%;
		top: 0;
	}
}

.partner-wrapper-no-right-border {
	&:after {
		display: none;
	}
}