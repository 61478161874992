// 
// Listing boxes - large
// 

.listing-box-detailed {
  display: grid;
  grid-auto-columns: 1.25fr 1fr;
  position: relative;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.15), 0 1px 3px 1px rgba(60, 64, 67, 0.075);
  @include font-size($font-size-sm);
  @include media-breakpoint-down(md) {
    grid-auto-columns: 1fr;
  }
  &__image {
    grid-row: 1;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    @include media-breakpoint-down(md) {
      grid-row: auto;
      height: auto;
    }
  }
  &__text-box {
    grid-row: 1;
    padding: 2rem;
    @include media-breakpoint-down(md) {
      grid-row: auto;
      padding: 1rem;
    }
  }
  &__description {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &__title {
    color: $primary;
    @include font-size($h2-font-size);
  }
  &__link {
    display: block;
    text-align: right;
    text-decoration: none;
    transition: $transition-base;
  }
}