//
// Banner slider
//

.banner-slider {
  &__item {
    position: relative;
    width: 100vw;
    &::before {
      display: block;
      content: "";
      padding-top: 29.4117647059%;
      @include media-breakpoint-down(md) {
        padding-top: 100%;
      }
    }
  }
  &__desktop-image,
  &__mobile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include media-breakpoint-down(md) {
    &__desktop-image {
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    &__mobile-image {
      display: none;
    }
  }
  &__content-container {
    position: absolute;
    bottom: 3rem;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }
  &__content-box {
    padding: 2rem 3rem;
    color: $light;
    font-family: $headings-font-family;
    @include font-size(1.35rem);
    line-height: $headings-line-height;
    background-color: rgba($primary, 0.85);
    max-width: 375px;
    @include media-breakpoint-down(md) {
      padding: 1rem 1.5rem;
      max-width: 100%;
    }
  }
  .swiper-pagination-bullet {
    background: $white;
    opacity: .75;
    &-active {
      background: $primary;
      opacity: 1;
    }
  }
}
