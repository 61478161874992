.comment-create {
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(#000, .08);
	padding: 20px;
}

.comments {
	list-style: none; 
	margin: 0;
	padding: 0;

	ul {
		list-style: none;
		margin: 0 0 0 80px;
		padding: 0;
	}
}

.comment {
	margin: 0 0 30px 0;
	position: relative;
}

.comment-author {
	background-color: $color-gray-medium;
	border-radius: 50%;
	height: 50px;
	left: 0;
	position: absolute;
	top: 0;
	transition: background-color .15s linear;
	width: 50px;

	a {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		bottom: 2px;
		border-radius: 50%;
		display: block;
		left: 2px;
		position: absolute;
		right: 2px;
		top: 2px;
	}

	&:hover {
		background-color: $primary;
	}
}

.comment-content {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin-left: 80px;
	padding: 20px;
}

.comment-meta {
	@extend .clearfix;

}

.comment-meta-date {	
	float: right;
	font-size: .9em;

	span {
		background-color: rgba(#000, .03);
		border-radius: 3px;
		color: lighten($color-gray-darker, 10%);
		padding: 4px 8px;
	}
}
.comment-meta-author {
	float: left;

	a {
		font-weight: 600;

		&:hover {
			text-decoration: none;
		}
	}
}

.comment-meta-reply {
	color: #fff;
	float: right;
	font-size: .9em;
	font-weight: 700;
	margin: 0 0 0 15px;

	a {
		background-color: $primary;
		border-radius: 3px;
		color: #fff;
		transition: background-color .15s linear;
		padding: 4px 8px;

		&:hover {
			background-color: darken($primary, 5%);
			text-decoration: none;
		}
	}
}

.comment-body {
	border-top: 1px solid rgba(#000, .04);
	color: lighten($color-gray-darker, 10%);
	margin: 20px 0 0 0;
	line-height: 24px;
	padding: 20px 0 0 0;
}

.comment-rating {
	color: $primary;
	font-size: 1.1em;
	margin: 0 0 10px 0;
}