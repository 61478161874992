.widget {
	margin: 0 0 30px 0;

	.widgettitle {
		border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    	font-size: 1.5em;
    	margin: 0 0 30px 0;
    	padding: 0 0 20px 0;
	}

	.menu {
		background-color: #fff;
		border-radius: 3px;
		box-shadow: 0 1px 2px rgba(#000, .08);

		.nav-link {			
			color: $color-gray-darker;
			display: block;
			padding: 12px 16px;

			&:hover {
				background-color: rgba(#000, .02);

				.fa {
					margin: 0 8px 0 0;
				}
			}

			.fa {
				color: $primary;
				font-size: .6em;
				margin: 0 5px 0 0;
				transition: margin .15s linear;
				vertical-align: middle;
			}
		}

		.nav-item {
			border-bottom: 1px solid rgba(#000, .04);
			margin: 0;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.mortgage-calculator {
		background-color: $primary;
		border-radius: 3px;
		box-shadow: 0 1px 2px rgba(#000, .08);
		padding: 25px;

		label {
			color: rgba(#fff, .9);
		}

		.form-control {
			border: 0;
		}

		.form-control-static {
			background-color: rgba(#fff, .2);
			color: #fff;
			font-weight: 700;
		}
	}

	.nav-style-primary {
		.nav-item {
			background-color: $primary;
			margin: 0;

			@for $i from 1 through 10 {
				&:nth-child(#{$i}) {    
					background-color: darken($primary, 20% / $i);
				}
			}
		}

		.nav-link {			
			color: #fff;
			display: block;
			font-weight: 600;
			padding: 15px 20px;
			transition: background-color .15s linear;

			&:hover {
				background-color: rgba(#000, .08);
			}

			.fa {
				opacity: .9;
				width: 30px;
			}
		}
	}

	.nav-style-secondary {
		.nav-item {
			margin: 0 0 12px 0;
		}

		.nav-link {
			background-color: $secondary;
			border-radius: 3px;
			color: #fff;
			font-weight: 600;
			padding: 14px 20px;
			transition: background-color .15s linear;

			&:hover {
				background-color: $primary;
			}

			.fa {
				margin: 0 8px 0 0;
			}
		}
	}
}

.widget-background-white {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	padding: 20px;
}