.listing-carousel-wrapper {
	position: relative;

	.owl-next, .owl-prev {
		background-color: #fff;
		box-shadow: 0 1px 2px rgba(#000, .08);
		color: $color-black;
		font-size: 10px;
		height: 34px;
		line-height: 36px;		
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		transition: opacity .15s linear;
		width: 34px;

		&:hover {

		}
	}
	
	.owl-prev {
		left: -47px;		
	}

	.owl-next {	
		right: -47px;
	}
}

.listing-contact-form {
	@extend .clearfix;

	background-color: #fff;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin: 0 0 30px 0;
	padding: 25px 20px;

	textarea {
		height: 210px;
	}
}

.listing-user {
	@extend .clearfix;
	margin: 0 0 30px 0;
	position: relative;
}

.listing-user-image {
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 1px 2px rgba(#000, .08);
	display: inline-block;
	height: 80px;
	margin: 0 30px 0 0;
	position: relative;
	transition: background-color .15s linear;
	vertical-align: middle;
	width: 80px;

	a {
		background-size: cover;
		background-position: center center;
		bottom: 3px;
		border-radius: 50%;
		display: block;
		left: 3px;
		position: absolute;
		right: 3px;
		top: 3px;
	}

	.listing-user:hover & {
		background-color: $primary;
	}
}

.listing-detail .listing-user-title, .listing-user-title {
	display: inline-block;
	vertical-align: middle;

	h2 {
		border: 0;
		margin: 0;
		padding: 0;
	}

	h3 {
		color: lighten($color-gray-darker, 15%);
		font-size: 1em;
		font-weight: 400;
		margin: 5px 0 0 0;
		padding: 0;
	}
}

.listing-user-social {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	@include media-breakpoint-down(md) {
		margin: 0 0 0 110px;
		position: relative;
		top: auto;
		transform: none;
	}
}