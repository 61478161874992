.invoice-wrapper {
	background-color: #fff;
	padding: 30px;
} 

.invoice-logo {
	font-size: 1.8em;

	.fa {
		color: $primary;
		margin: 0 20px 0 0;
	}
}

.invoice-description {
	margin-top: 30px;
}

.invoice-info {
	background-color: rgba(#000, .02);
	font-size: .95em;
	margin: 30px 0;
	line-height: 2;
	padding: 20px;

	h4 {
		font-size: 1.6em;
		margin: 0 0 20px 0;
	}
}

.invoice-summary {
	dl {
		background-color: rgba(#000, .02);
		margin: 0;
		padding: 20px;
	}
}