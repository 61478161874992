// 
// Swiper styles 
// 

@import 'abstracts/variables';

$themeColor: $primary !default;
$colors: ('white': $white, 'black': $black) !default;

@import '../../../node_modules/swiper/swiper';
@import '../../../node_modules/swiper/components/pagination/pagination';
@import '../../../node_modules/swiper/components/navigation/navigation';
@import '../../../node_modules/swiper/components/zoom/zoom';
@import '../../../node_modules/swiper/components/lazy/lazy';
@import '../../../node_modules/swiper/components/effect-fade/effect-fade';

.swiper-pagination {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > .swiper-pagination-bullet {
    padding: 0 4px;
    width: 10px;
    height: 10px;
    transition: all .1s cubic-bezier(.15,.5,1,.5);
  }
}