.content-title {
	background-color: $primary;
	background-image: linear-gradient(to right, darken($primary, 10%), lighten($primary, 10%));  
	padding: 2rem 0;

	h1 {
		color: $light;
		margin-bottom: 0;
	}

	.breadcrumb {
		background-color: transparent;	
		border-radius: 0;
		color: #fff;
		margin: 5px 0 0 0;
		padding: 0;

		li {
			&:before {
				color: rgba(#fff, .4);	
				margin: 0 10px;
			}
		}

		a {
			color: #fff;
		}

		> .active {
			color: #fff;	
		}

		.content-title-center & {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.content-title-inner {
	// background-image: url('/assets/img/noise.png');
    background-position: center center;
    background-repeat: repeat;
}