.post {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	margin: 0 0 30px 70px;
	padding: 20px;
	position: relative;

	.posts-grid & {
		margin-left: 0;
	}

	&:hover {
		.post-image {
			&:after, &:before {
				opacity: 1;
			}
		}

		.post-author {
			bottom: 25px;	
		}

		.post-title {
			a {
				color: $primary;
			}
		}

		.post-author-image {
			background-color: $primary;
		}
	}
}

.post-image {
	height: 200px;
	margin: 0 0 15px 0;
	position: relative;

	&:before {
		background-color: rgba(#000, .3);
		bottom: 0;
		content: '';
		left: -20px;
		opacity: 0;
		position: absolute;
		pointer-events: none;
		right: -20px;
		top: -20px;
		transition: opacity .15s linear;
		z-index: 3;
	}

	&:after {		
		-webkit-font-smoothing: antialiased;	
		background-color: $primary;
		border-radius: 50%;
		color: #fff;
		content: '\f002';
		display: block;
		font-family: 'FontAwesome';		
		height: 40px;
		left: 50%;
		line-height: 38px;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		text-align: center;
		top: 50%;		
		transform: translateX(-50%) translateY(-50%); 		
		transition: opacity .15s linear;
		width: 40px;
		z-index: 3;
	}
}

.post-image-link {		
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;		
	border-radius: 3px 3px 0 0;
	bottom: 0;		
	display: block;
	left: -20px;
	position: absolute;
	right: -20px;
	top: -20px;

	&:after {
		background-image: linear-gradient(to top, rgba(#000, .5), transparent 100px);
		bottom: 0;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}
}

.post-author {
	bottom: 20px;
	display: block;
	left: 0px;
	position: absolute;
	transition: all .15s linear;
	z-index: 10;

	a {
		&:hover {
			text-decoration: none;
		}
	}
}

.post-author-image {
	background-color: #fff;
	border-radius: 50%;
	display: inline-block;
	height: 34px;
	margin: 0 10px 0 0;
	position: relative;
	transition: background-color .15s linear;
	vertical-align: middle;
	width: 34px;

	a {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		border-radius: 50%;
		bottom: 2px;
		display: block;
		left: 2px;
		position: absolute;
		right: 2px;
		top: 2px;		
	}

	&:hover {
		background-color: $primary;
	}
}

.post-author-name {
	color: #fff;
	font-weight: bold;
	vertical-align: middle;
}

.post-title {
	display: block;
	margin: 0 0 20px 0;	

	h2 {
		font-weight: 400;
		line-height: 1;
		margin: 0;
		overflow: hidden;
		padding: 0;					
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	a {		
		color: $color-black;
		font-size: .6em;	

		&:hover {
			text-decoration: none;
		}
	}
}

.post-meta {
	@extend .clearfix;

	border-bottom: 1px solid rgba(#000, .04);
	border-top: 1px solid rgba(#000, .04);
	font-size: .9em;
	margin: 0 0 20px 0;
	padding: 7px 0;

	.fa {
		color: $color-gray-dark;
		margin: 0 10px 0 0;
	}

	.post-meta-item {
		border-right: 1px solid rgba(#000, .04);
		float: left;
		margin: 0 10px 0 0;
		padding: 2px 10px 2px 0;

		a {
			&:hover {
				text-decoration: none;
			}
		}

		&:last-child {
			border-right: 0;
		}
	}
}

.post-content {
	p {
		color: lighten($color-gray-darker, 10%);
		font-size: .95em;
		margin: 0;
	}
}

.post-read-more {
	@extend .clearfix;
	margin: 10px 0 0 0;

	a {
		float: right;
		font-weight: 600;
		transition: color .15s linear;

		.fa {
			font-size: .7em;
			margin: 0 0 0 10px;
		}

		&:hover {
			text-decoration: none;
		}
	}
}

.post-date {
	background-color: #fff;
	border-radius: 3px;
	left: -70px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 46px;

	span, strong {
		display: block;
	}

	strong {
		background-color: $primary;
		border-radius: 3px 3px 0 0;
		color: #fff;
		font-size: 1.4em;
		font-weight: 600;
		padding: 5px 0;
	}

	span {
		background-color: $secondary;
		border-radius: 0 0 3px 3px;
		color: #fff;
		font-size: .85em;
		padding: 4px 0;
	}
}