.listing-row {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(#000, .08);
	display: table;
	margin: 0 0 30px 0;
	min-height: 180px;
	transition: box-shadow .15s linear;
	width: 100%;

	&:hover {
		box-shadow: 0 2px 3px rgba(#000, .12);

		.listing-row-image-links {
			opacity: 1;
		}

		.listing-row-content {
			h3 {
				color: $primary;

				a {
					color: $primary;					
				}
			}
		}
	}
}

.listing-row-inner {
	display: table-row;
}

.listing-row-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;	
	border: 3px 0 0 3px;
	display: table-cell;
	overflow: hidden;
	position: relative;
	width: 30%;

	.listing-row-simple-image & {
		background-size: inherit;		
		border-right: 1px solid rgba(#000, .08);

		@include media-breakpoint-down(md) {
			border-right: 0;
		}
	}

	@include media-breakpoint-down(md) {
		display: block;
		height: 300px;
		width: 100%;
	}
}

.listing-row-subtitle {
	border-bottom: 1px solid rgba(#000, .04);
	color: lighten($color-gray-darker, 10%);
	font-weight: 600;
	margin: -10px 0 30px 0;
	padding: 0 0 20px 0;
}

.listing-row-actions {
	list-style: none;
	margin: 0 0 10px 0;
	padding: 0;

	li {
		float: left;
		margin: 0 10px 0 0;

		@include media-breakpoint-down(md) {
			display: block;
			float: none;
			margin: 0 0 10px 0;

			a {
				display: block;
			}
		}

		a {
			background-color: rgba(#000, .04);
			border-radius: 3px;
			color: $color-black-light;
			font-size: .9em;
			font-weight: 700;
			padding: 7px 12px;
			transition: background-color .15s linear;

			.fa {
				color: lighten($color-gray-darker, 25%);
				margin: 0 6px 0 0;
			}

			&:hover {
				background-color: rgba(#000, .06);
				text-decoration: none;
			}
		}
	}
}

.listing-row-content {
	display: table-cell;
	padding: 20px;
	position: relative;
	width: 70%;

	@include media-breakpoint-down(md) {
		display: block;
		width: 100%;
	}

	h3 {
		font-size: 1.4em;
		margin: 0 0 20px 0;

		a {			
			color: $color-black;
			display: block;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: color .15s linear;
			white-space: nowrap; 

			&:hover {
				color: $primary;
				text-decoration: none;
			}
		}

		span {
			color: lighten($color-gray-darker, 15%);
			font-size: .6em;
			font-weight: 700;
			margin: 0 0 0 10px;
			vertical-align: middle;
		}
	}

	h4 {
		background-color: $primary;
		color: #fff;
		font-size: .9em;
		font-weight: 700;
		padding: 5px 12px;
		position: absolute;
		right: 20px;
		top: 20px;
	}

	p {
		color: lighten($color-gray-darker, 10%);
	}
}

.listing-box-social, .listing-user-social ul, .listing-row-social {
	@extend .clearfix;
	list-style: none;
	margin: 0 0 10px 0;
	padding: 0;

	li {
		float: left;
		margin: 0 15px 0 0;

		a {
			background-color: #fff;
			border: 1px solid $color-gray-dark;
			border-radius: 50%;
			color: lighten($color-gray-darker, 10%);
			display: block;
			height: 28px;
			font-size: .9em;
			line-height: 26px;
			text-align: center;
			transition: all .15s linear;
			width: 28px;

			&:hover {
				border-color: $primary;
				color: $primary;
			}
		}
	}
}

.listing-row-attributes {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		@extend .clearfix;

		font-size: .9em;
		float: left;
		margin: 0;
		padding: 10px 20px; 
		position: relative;
		width: 50%;

		.fa {
			color: $primary;
			margin: 0 7px 0 0;
			text-align: center;
			width: 16px;

			@include media-breakpoint-down(md) {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}

		strong {
			color: lighten($color-gray-darker, 15%);
			font-weight: 400;
		}

		span {
			float: right;
			font-weight: 600;
		}

		&:after {
			background-color: $color-gray;
			bottom: 10px; 
			content: '';
			display: block;
			position: absolute;
			right: 0px;
			top: 10px;
			width: 1px;
		}

		&:nth-child(2n-1) {				   
			padding-left: 10px; 

			&:before {
				left: 0;
			}
		}

		&:nth-child(2n) {
			padding-right: 10px;

			&:after {
				display: none;
			}

			&:before {
				right: 0;
			}
		}

		&:before {
			background-color: $color-gray;
			bottom: 0;
			content: '';
			display: block;
			height: 1px;
			position: absolute;
			left: 10px;
			right: 10px;				
		}

		&:nth-last-child(1), &:nth-last-child(2) {
			&:before {
				display: none;
			}
		}
	}
}

.listing-row-image-links {
	background-color: rgba(black, .3);
	bottom: 0;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	transition: opacity .15s linear;

	a {
		align-items: center;
		background-color: $primary;
		color: #fff;
		display: flex;
		font-size: .95em;
		font-weight: 600;
		text-align: left;
		height: 33.33333333%;
		padding: 0 0 0 20px;	
		position: relative;
		transition: padding .15s linear, background-color .15s linear;	
		width: 100%;
		z-index: 11;

		.fa {
			font-size: 1.2em;
			width: 30px;
		}

		&:first-child {
			background-color: rgba(darken($primary, 15%), .9);

			&:hover {
				background-color: rgba(darken($primary, 15%), 1);				
			}
		}

		&:nth-child(2) {
			background-color: rgba(darken($primary, 10%), .9);

			&:hover {
				background-color: rgba(darken($primary, 10%), 1);				
			}			
		}		

		&:nth-child(3) {
			background-color: rgba(darken($primary, 5%), .9);

			&:hover {
				background-color: rgba(darken($primary, 5%), 1);				
			}						
		}		

		&:hover { 			
			text-decoration: none;
		}
	}
}